import React from "react";

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import PrimaryButton from "../common/Buttons/Primary";

export interface IArrow {
  prev?: boolean
  hasPrev: boolean
  label: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
} 

const Arrow = ({ prev = false, onClick, label, hasPrev }: IArrow) => (
  <PrimaryButton
    className={`h-12 w-7 ${
      prev ? "mr-0 rounded-l-full" : "ml-0 rounded-r-full"
    } bg-black bg-opacity-20 z-10 absolute top-1/2 ${
      prev ? "right-0" : "left-0 "
    } transform -translate-y-1/2 `}
    onClick={onClick}
  >
    {prev ? (
      <IoIosArrowForward className="text-base " />
    ) : (
      <IoIosArrowBack className="text-base " />
    )}
  </PrimaryButton>
);

export default Arrow;
