import React from "react";

import PollThumbnail from "components/Polls/Thumbnail";
import useGlobalContext from "hooks/common/useGlobalContext";

import PollSection from "../../../components/Polls/Section";

const CurrentPolls: React.FunctionComponent = () => {
  const { globalState } = useGlobalContext();
  const data = globalState.polls;

  return (
    <PollSection 
      title="Ongoing DareMe"
    >
      {data.map(({category, deadline, creator, ...item}) => 
        !item.expired && <PollThumbnail 
          key={item.id} 
          category={category} 
          deadline={new Date(deadline)} 
          user={{
            displayName: creator.displayName,
            photoURL: creator.photoURL
          }}
          {...item}/>
      )}
    </PollSection>
  );
};

export default CurrentPolls;
