import { useCallback, useEffect, useMemo, useState } from "react";

import { PollQuery, getPolls } from "api/poll";
import dayjs from "dayjs";

import { POLL_SET_POLLS_TYPE } from "../../actions/global";
import useGlobalContext from "../common/useGlobalContext";
import useLoading from "../common/useLoading";

import "dayjs/plugin/utc";

var utc = require("dayjs/plugin/utc")
dayjs.extend(utc)

export interface PollsUpdaterHookInterface {
  query?: PollQuery
}

const usePollsUpdater = () => {
  const { globalState, globalDispatch } = useGlobalContext();

  const { setLoading } = useLoading(true);

  const getAllPolls = useCallback(async () => {
    try {
      const response = await getPolls();
      // const polls = response!.data.map((ele: PollResponse) => {
      //   const creator = new schema.Entity('creator');
      //   const option = new schema.Entity('options', {
      //     creator: creator
      //   });
      //   const poll = new schema.Entity('poll', {
      //     creator: creator,
      //     options: [option]
      //   });
      //   const now = new Date().valueOf();
      //   const deadline = new Date(ele.deadline).valueOf();
      //   const expired = deadline < now;
      //   const newPoll = {
      //     ...ele,
      //     expired,
      //   };
        
      //   return normalize(newPoll, poll);
      // })

      globalDispatch({
        type: POLL_SET_POLLS_TYPE,
        payload: response!.data,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  },[globalDispatch, setLoading])

  useEffect(() => {      
    getAllPolls();
  }, []);

  return useMemo(
    () => ({
      polls: globalState.polls,
    }),

    [globalState.polls]
  );
};

export default usePollsUpdater;
