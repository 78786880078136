import React from 'react'

import { CarouselProps, Carousel as RCarousel} from 'react-responsive-carousel';

import Arrow from './Arrow'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./carousel.css"

const leftArrow: CarouselProps["renderArrowPrev"]= (clickHandler : () => void , hasPrev : boolean, label : string) => <Arrow prev={true} onClick={clickHandler} hasPrev={hasPrev} label={label} />
const rightArrow: CarouselProps["renderArrowNext"] = (clickHandler : () => void , hasPrev : boolean, label : string) => <Arrow prev={false} onClick={clickHandler} hasPrev={hasPrev} label={label} />

const Carousel: React.FunctionComponent<Partial<CarouselProps>> = ({ children }) => (
  <RCarousel
    autoFocus={false}
    autoPlay={true}
    emulateTouch={true}
    infiniteLoop={true}
    interval={4000}
    showStatus={false}
    showThumbs={false}
    renderArrowNext = {leftArrow}
    renderArrowPrev={rightArrow}
  >
    {children}
  </RCarousel>    
)

export default Carousel
