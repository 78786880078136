import React from "react";

import Carousel from "components/Carousel";
import SEO from "components/seo";
import { StaticImage } from "gatsby-plugin-image";
import CurrentPolls from "templates/poll/list/current";
import PastPolls from "templates/poll/list/past";

import usePollsUpdater from "../hooks/polls/usePollsUpdater";

const IndexPage: React.FunctionComponent = () => {
  usePollsUpdater();

  return (
    <>
      <Carousel>
        <StaticImage
          src="../images/carousel/banner_1.jpg"
          alt="creato together, i am a content creator"
          layout="fullWidth"
        />
        <StaticImage
          src="../images/carousel/banner_2.jpg"
          alt="creato together, why not both"
          layout="fullWidth"
        />
        <StaticImage
          src="../images/carousel/banner_3.jpg"
          alt="creato together, i love"
          layout="fullWidth"
        />
      </Carousel>
      <CurrentPolls />
      <PastPolls />
    </>
  );
};

export default IndexPage;
